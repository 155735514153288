import React, { useState } from "react"

import { Helmet as _Helmet } from "react-helmet"
import Footer from "../../components/footer"
const Helmet: any = _Helmet

export default function Home({ data }) {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Footer isWidget={true} />
    </div>
  )
}
